i,
mat-icon {
    font-size: 20px !important;
    width: 26px;
    height: 26px;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @for $size from 2 through 128 {
                &.s#{$infix}-#{$size * 2} {
                    font-size: #{($size * 2) + 'px'} !important;
                    width: #{($size * 2) + 'px'} !important;
                    height: #{($size * 2) + 'px'} !important;
                    min-width: #{($size * 2) + 'px'} !important;
                    min-height: #{($size * 2) + 'px'} !important;
                    line-height: #{($size * 2) + 'px'} !important;
                }
            }
        }
    }
}
