.hot-toast-bar-base {
    overflow: hidden;
    align-items: var(--hot-toast-align-items, center);
    background-color: var(--hot-toast-bg, #fff);
    border-radius: var(--hot-toast-border-radius, 4px);
    box-shadow: var(--hot-toast-shadow, 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05));
    color: var(--hot-toast-color, #363636);
    display: var(--hot-toast-display, flex);
    line-height: var(--hot-toast-line, 1.3);
    margin: var(--hot-toast-margin, 16px);
    max-width: var(--hot-toast-max-width, 350px);
    padding: var(--hot-toast-padding, 8px 10px);
    pointer-events: var(--hot-toast-pointer-events, auto);
    width: var(--hot-toast-width, fit-content);
    transition-property: border-bottom-left-radius, border-bottom-right-radius;
    transition-duration: 230ms;
    transition-timing-function: ease-out;
    // will-change: var(--hot-toast-will-change, transform);

    &:hover,
    &:focus {
        animation-play-state: var(--hot-toast-animation-state, paused) !important;
    }

    @media (prefers-reduced-motion: reduce) {
        animation-duration: var(--hot-toast-reduced-motion-animation-duration, 10ms) !important;
    }

    .expanded & {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.hot-toast-message {
    overflow: overlay;
    color: var(--hot-toast-message-color, inherit);
    display: var(--hot-toast-message-display, flex);
    flex: var(--hot-toast-message-flex, 1);
    justify-content: var(--hot-toast-message-justify-content, center);
    margin: 4px;
}

.hot-toast-bar-base-container {
    display: var(--hot-toast-container-display, flex);
    pointer-events: var(--hot-toast-container-pointer-events, none);
    position: var(--hot-toast-container-position, absolute);
    transition: var(--hot-toast-container-transition, transform 230ms cubic-bezier(0.21, 1.02, 0.73, 1));

    @media (prefers-reduced-motion: reduce) {
        transition-duration: var(--hot-toast-container-reduced-motion-transition-duration, 10ms) !important;
    }

    &.hot-toast-theme-snackbar {
        .hot-toast-bar-base {
            background: var(--hot-toast-snackbar-bg, #323232);
            box-shadow: var(
                --hot-toast-snackbar-shadow,
                0 3px 5px -1px rgba(0, 0, 0, 0.2),
                0 6px 10px 0 rgba(0, 0, 0, 0.14),
                0 1px 18px 0 rgba(0, 0, 0, 0.12)
            );
            color: var(--hot-toast-snackbar-color, rgba(255, 255, 255));
        }

        .hot-toast-close-btn {
            filter: var(--hot-toast-snackbar-close-btn-filter, invert(1) grayscale(100%) brightness(200%));
        }
    }
}

.hot-toast-close-btn {
    position: sticky;
    top: 0;
    align-self: var(--hot-toast-close-btn-align-self, flex-start);
    background-color: var(--hot-toast-close-btn-background-color, transparent);
    background-image: var(
        --hot-toast-close-btn-background-image,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    );
    background-position: var(--hot-toast-close-btn-background-position, center);
    background-repeat: var(--hot-toast-close-btn-background-repeat, no-repeat);
    background-size: var(--hot-toast-close-btn-background-size, 0.75em);
    border: var(--hot-toast-close-btn-border, 0);
    border-radius: var(--hot-toast-close-btn-border-radius, 0.25rem);
    box-sizing: var(--hot-toast-close-btn-box-sizing, content-box);
    display: var(--hot-toast-close-btn-display, flex);
    height: var(--hot-toast-close-btn-height, 0.8em);
    margin-top: var(--hot-toast-close-btn-margin-top, 0.25em);
    opacity: var(--hot-toast-close-btn-opacity, 0.5);
    padding: var(--hot-toast-close-btn-padding, 0.25em);
    width: var(--hot-toast-close-btn-width, 0.8em);

    &:focus {
        box-shadow: var(--hot-toast-close-btn-box-shadow, 0 0 0 0.125rem rgb(13 110 253 / 25%));
        outline: var(--hot-toast-close-btn-outline, none);
    }

    &:hover,
    &:focus {
        opacity: var(--hot-toast-close-btn-opacity, 0.75);
    }
}

.hot-toast-group-btn {
    align-self: var(--hot-toast-group-btn-align-self, flex-start);
    background-color: var(--hot-toast-group-btn-background-color, transparent);
    background-image: var(
        --hot-toast-group-btn-background-image,
        url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.75745 10.5858L9.17166 9.17154L12.0001 12L14.8285 9.17157L16.2427 10.5858L12.0001 14.8284L7.75745 10.5858Z' fill='currentColor' /%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z' fill='currentColor' /%3E%3C/svg%3E")
    );
    background-position: var(--hot-toast-group-btn-background-position, center);
    background-repeat: var(--hot-toast-group-btn-background-repeat, no-repeat);
    background-size: var(--hot-toast-group-btn-background-size, 1.3em);
    border: var(--hot-toast-group-btn-border, 0);
    border-radius: var(--hot-toast-group-btn-border-radius, 0.25rem);
    box-sizing: var(--hot-toast-group-btn-box-sizing, content-box);
    display: var(--hot-toast-group-btn-display, flex);
    height: var(--hot-toast-group-btn-height, 0.8em);
    margin-top: var(--hot-toast-group-btn-margin-top, 0.25em);
    opacity: var(--hot-toast-group-btn-opacity, 0.5);
    padding: var(--hot-toast-group-btn-padding, 0.25em);
    width: var(--hot-toast-group-btn-width, 0.8em);
    will-change: var(--hot-toast-group-btn-will-change, transform);
    transition: var(--hot-toast-group-btn-transition, transform 230ms cubic-bezier(0.21, 1.02, 0.73, 1));

    &:focus {
        box-shadow: var(--hot-toast-group-btn-box-shadow, 0 0 0 0.125rem rgb(13 110 253 / 25%));
        outline: var(--hot-toast-group-btn-outline, none);
    }

    &:hover,
    &:focus {
        opacity: var(--hot-toast-group-btn-opacity, 0.75);
    }

    .expanded & {
        transform: rotate(var(--hot-toast-group-btn-expanded-rotate, 180deg));
    }
}

.hot-toast-icon {
    align-self: var(--hot-toast-icon-align-self, flex-start);
    padding-top: var(--hot-toast-icon-padding-top, 0.25em);
}

.hot-toast-bar-base-wrapper {
    pointer-events: all;
}

.hot-toast-bar-base-group {
    // remove shadow for child toasts
    --hot-toast-shadow: none;

    background-color: var(--hot-toast-group-bg, #fff);
    margin: var(--hot-toast-margin, 16px);
    margin-top: calc(-1 * var(--hot-toast-margin, 16px));
    border-bottom-left-radius: var(--hot-toast-border-radius, 4px);
    border-bottom-right-radius: var(--hot-toast-border-radius, 4px);
    height: 0;
    overflow: hidden;
    transition-property: height;
    transition-duration: 230ms;
    transition-timing-function: ease-in-out;
    position: relative;
    box-shadow: var(--hot-toast-group-after-shadow, 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05));

    .expanded & {
        height: var(--hot-toast-group-height);
    }

    .hot-toast-bar-base {
        margin: 0;
    }
}

@keyframes hotToastEnterAnimationNegative {
    0% {
        opacity: 0.5;
        transform: translate3d(0, -80px, 0) scale(0.6);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@keyframes hotToastEnterAnimationPositive {
    0% {
        opacity: 0.5;
        transform: translate3d(0, 80px, 0) scale(0.6);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1);
    }
}

@keyframes hotToastExitAnimationPositive {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, var(--hot-toast-exit-positive-y, 130px), -1px) scale(0.5);
    }
}

@keyframes hotToastExitAnimationNegative {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, var(--hot-toast-exit-negative-y, -130px), -1px) scale(0.5);
    }
}

@keyframes hotToastEnterSoftAnimationNegative {
    0% {
        opacity: 0.5;
        transform: translate3d(0, -14px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes hotToastEnterSoftAnimationPositive {
    0% {
        opacity: 0.5;
        transform: translate3d(0, 14px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes hotToastExitSoftAnimationPositive {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, 14px, -1px);
    }
}

@keyframes hotToastExitSoftAnimationNegative {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, -1px);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -14px, -1px);
    }
}
