/* Fix: "Icon button ripple radius is not correct on Edge & Safari"*/
.mat-mdc-icon-button {
    font-size: 20px;
    .mat-mdc-button-ripple {
        border-radius: 50%;
    }
}

*[class*='mat-'],
*[class*='mat-mdc-'] {
    -webkit-font-smoothing: auto !important;
    -moz-osx-font-smoothing: auto !important;

    * {
        -webkit-font-smoothing: auto !important;
        -moz-osx-font-smoothing: auto !important;
    }
}

/* Fix: "Smooth scrolling for iOS"*/
.mat-mdc-dialog-container {
    -webkit-overflow-scrolling: touch;
    resize: none !important;
}

.mat-mdc-radio-button {
    font-size: 15px;
}

.mat-pseudo-checkbox-checked:after {
    width: 15px !important;
    height: 7px !important;
}

/* Fix: "Some idiots using table-cell and inline-table in mat-select"*/
.mat-mdc-form-field {
    font-size: 14px !important;
}

.mat-mdc-select {
    font-size: 14px !important;
}

/* Fix: Stepper icons are broken due to Fuse's icon helpers*/
mat-horizontal-stepper,
mat-vertical-stepper {
    mat-step-header {
        mat-icon {
            height: 16px !important;
            width: 16px !important;
            min-width: 0 !important;
            min-height: 0 !important;
            color: inherit !important;
        }
    }
}

mat-vertical-stepper {
    padding: 16px 0;
}

/* Fix: Mat-card-image requires a bigger width than 100%*/
.mat-mdc-card-image {
    max-width: none !important;
}
.mdc-button {
    .mdc-button__label {
        white-space: nowrap;
    }
}
.mat-mdc-checkbox {
    display: inline-flex !important;
    font-size: 15px;
    .mdc-form-field {
        padding-right: 12px;
    }
}

input,
textarea {
    background: transparent;
    /* Autofill color fix */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-background-clip: text;
        transition: background-color 5000s !important;
    }

    [data-autocompleted] {
        background-color: transparent !important;
    }
}

.mat-mdc-form-field-error {
    display: flex !important;
    //font-size: 11px;
    flex-direction: column-reverse;
}

.mat-mdc-form-field-infix {
    width: inherit !important;
}
