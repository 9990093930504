/* Import Fuse core library*/
@use '@fuse/scss/core';

a:hover {
    cursor: pointer;
}

.cdk-global-scrollblock {
    position: fixed;
    width: 100%;
    overflow-y: hidden;
}

.mat-pseudo-checkbox {
    padding: 9px;
}

.mat-mdc-dialog-title {
    padding-left: 16px !important;
    padding-top: 16px !important;
    margin: 0 !important;
    /*border-bottom: 0.4px solid lightgray;*/
}

.mat-dialog-content {
    display: flex !important;
    overflow: auto !important;
    padding-top: 16px !important;
    padding-bottom: 12px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    max-height: 80vh;
    margin: unset !important;
}

@media (max-width: 599px) {
    .mat-mdc-dialog-content {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }
}

.mobile-content-buttonbar {
    /*height: calc(100vh - 118px) !important;*/
    max-height: calc(100vh - 118px) !important;
    /* min-height: calc(100vh - 118px) !important;*/
}

.mobile-content-full-screen {
    /*height: calc(100vh - 118px) !important;*/
    max-height: calc(100vh - 150px) !important;
    min-height: calc(100vh - 150px) !important;
}

.mat-mdc-dialog-actions {
    margin-bottom: 0px !important;
    min-height: unset !important;
    /*width: 100%;*/
    justify-content: flex-end;
}

/*CUSTOM CSS*/
.form {
    min-width: 150px;
    width: 100%;
    height: 100%;
}

.full-width {
    width: 100%;
}

.button-bar {
    width: 100%;
    margin-top: 10px;
}

.header {
    padding: 0px !important;
    width: 100%;
}

.full-size {
    width: 100%;
    height: 100%;
}

.control-full-margin {
    width: 100%;
}

.control-right-align {
    text-align: right;
}

.divider {
    position: unset !important;
    margin-bottom: 16px !important;
    border-top-width: 1px !important;
    background-color: black;
    color: rgba(0, 0, 0, 0.12);
}

.form-subtitle {
    color: grey;
    /* background: rgba(0,0,0,.03);*/
    align-content: center;
    align-items: center;
    display: flex;
    /*padding: 0px 2px 0px;*/
    margin-bottom: 16px !important;
    margin-top: 0px !important;
}
.subtitle {
    padding-bottom: 12px;
    padding-top: 8px;
    font-size: 15px;
}

tr.mat-mdc-row:not(.example-expanded-row .highlight .mat-mdc-table-selected-row):hover {
    background: #f5f5f5;
}

tr.mat-mdc-row:not(.example-expanded-row .highlight .mat-mdc-table-selected-row):active {
    background: #efefef;
}

.mat-mdc-row:not(.highlight .mat-mdc-table-selected-row):hover {
    background: #f5f5f5;
}

[mat-mdc-row].remove-background {
    background: none;
}

[mat-mdc-row].mat-mdc-table-selected-row {
    //color: theming.mat-color(base-theme.$primary);
    background: #c2dbff;
    td.mat-mdc-cell {
        background: #c2dbff;
    }
}

[mat-mdc-row].mat-mdc-table-selected-row:hover {
    //color: theming.mat-color(base-theme.$primary);
    background: #c2dbff !important;
    opacity: 0.85;
    td.mat-mdc-cell {
        background: #c2dbff;
    }
}

tr.mat-mdc-row .mat-mdc-table-sticky:is(.mat-mdc-table-selected-row):hover {
    background: #c2dbff !important;
}

.mat-mdc-table-sticky {
    background: white;
    white-space: nowrap;
    position: sticky;
    z-index: 100;
}

[mat-mdc-cell].mat-mdc-table-sticky {
    border-right: 1px solid #e2e2e2;
}
[mat-mdc-row].mat-mdc-table-selected-row > .mat-mdc-table-sticky {
    color: #3c4252;
    background: #c2dbff !important;
}

.mat-spacer {
    flex: 1 1 auto;
}

.mat-tree-node {
    background-color: white;
    user-select: none;
    min-height: 32px !important;
    // height: 32px !important;
    &.cdk-drag-preview {
        /* while dragging*/
        //@include mat.mat-elevation(12);
    }
    &.cdk-drag-placeholder {
        /* potential drop area*/
        opacity: 0;
    }
}

.danger {
    background: #d32f2f !important;
    color: #fff;
}

.danger-text {
    color: #d32f2f !important;
}

.success {
    background: #43a047 !important;
    color: #fff;
}

.success-text {
    color: #43a047;
}

.info {
    background: #1976d2 !important;
    color: #fff;
}
.info-text {
    color: #1976d2;
}

.warn {
    background: #ffa000 !important;
    color: #fff;
}

.warning {
    background: #ffa000 !important;
    color: #fff;
}

.warning-text {
    color: #ffa000;
}

.lesson-type {
    color: rgb(3, 155, 229);
}

.status-label {
    padding-left: 6px;
    padding-right: 6px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    border-width: 1.5px;
    border-style: solid;
    display: flex;
    height: 26px;
    align-items: center;
    justify-content: center;
}

tr.mat-mdc-footer-row,
tr.mat-mdc-row {
    cursor: pointer;
}

tr.mat-mdc-row,
tr.mat-mdc-footer-row {
    height: 48px;
}

td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type,
th.mat-mdc-header-cell:first-of-type {
    padding-left: 8px !important;
}

td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type,
th.mat-mdc-header-cell:last-of-type {
    /* padding-right: 8px !important;*/
    padding-left: 8px !important;
}

td.mat-mdc-footer-cell {
    padding-right: 8px !important;
    padding-left: 8px !important;
    background: #f6f6f6;
    color: rgba(0, 0, 0, 0.65);
}

td.mat-mdc-cell {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

td.mat-mdc-cell > .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
    padding-bottom: 1.27em;
}

.mat-mdc-form-field-subscript-wrapper {
    font-size: 11px !important;
}

.bottom-speed-dial {
    /*position: absolute !important;*/
    /*bottom: 16px;*/
    /*right: 8px;*/
    padding-right: 8px !important;
}

.simple-dialog-title {
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
}

/**DISABLE STEPPER HEADER CLICK**/
.mat-horizontal-stepper-header {
    pointer-events: none !important;
}

.mat-vertical-stepper-header {
    pointer-events: none !important;
}

/**START CUSTOM CSS OF PRE-REGISTRATION FORM STEPPER**/
@media (max-width: 599px) {
    .mat-horizontal-stepper-header .mat-step-icon,
    .mat-horizontal-stepper-header .mat-step-icon-not-touched {
        margin-right: 0 !important;
    }
}

.mat-horizontal-stepper-header-container {
    padding-bottom: 40px !important;
}

@media (max-width: 599px) {
    .mat-step-label {
        display: block !important;
    }
}

.mat-mdc-list .mat-mdc-list-item .mat-list-item-content {
    padding: 0px !important;
}

.mat-mdc-list-option[aria-selected='true'] {
    background: rgba(216, 215, 215, 0.5);
}

.mat-expansion-panel-body {
    padding: 12px 12px 12px 12px !important;
}

.mat-expansion-panel-header {
    padding: 0 16px !important;
    height: 48px !important;
}

/**END CUSTOM CSS OF PRE-REGISTRATION FORM STEPPER**/

/** STATUS COLORS **/
.color-red {
    color: #f44336;
}

.color-green {
    color: #388e3c;
}

.color-grey {
    color: #d3d3d3;
}

.mat-mdc-list .mat-mdc-list-item {
    height: 48px !important;
}

/*when you want to fill mat tab with full mat group height*/
.mat-mdc-tab-group.mat-mdc-tab-fill-height .mat-mdc-tab-body-wrapper {
    flex: 1 1 100%;
}

.nav-link {
    height: 44px !important;
}

tr.mat-mdc-footer-row,
.mat-mdc-paginator-range-actions {
    flex: 1 1 auto;
}

.mat-mdc-menu-item {
    line-height: 44px !important;
    height: 44px !important;
    min-height: 44px !important;
    white-space: nowrap;
}

.tooltip-new-line {
    white-space: pre-line !important;
}

.mat-mdc-tooltip {
    //background-color: theming.mat-color(base-theme.$primary);
    color: white;
    font-size: small;
}

.mat-bottom-sheet-container {
    padding: 0px !important;
}

.formula-error-row {
    background: #f1afaf !important;
}

.formula-error-cell {
    background: #e66c6c !important;
}

.new-item {
    background: #fff176 !important;
}

.ag-theme-material {
    font-size: unset;
}

.table-currency-cell {
    white-space: nowrap;
    padding-right: 4px;
    text-align: right !important;
}

.table-center-cell {
    white-space: nowrap;
    overflow: hidden;
    padding-right: 4px;
    text-align: center !important;
}
.center-header .mat-sort-header-content {
    justify-content: center;
    width: 100%;
}

.right-header .mat-sort-header-content {
    justify-content: end;
    width: 100%;
}

.left-header .mat-sort-header-content {
    justify-content: start;
}

.mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
    white-space: nowrap;
}

/* Structure */
.example-container {
    width: 100% !important;
    padding: 12px;
    height: 100% !important;
}

.mat-toolbar-multiple-rows {
    min-height: 48px !important;
}

[disabled] {
    pointer-events: none;
}

@media only screen and (max-width: 959px) {
    .mat-toolbar-row,
    .mat-toolbar-single-row {
        height: 48px;
        padding-top: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0px !important;
        padding-left: 0px !important;
    }
    .mat-mdc-paginator-range-label {
        display: none;
    }
}

.toaster-template {
    /*background-color: rgb(119, 93, 208) !important;*/
    //color: white !important;
    overflow: hidden;
    font-size: 14px !important;
    max-width: calc(65vw - 0px) !important;
}

.toaster-template:hover {
    overflow: overlay;
}

.mat-mdc-dialog-container {
    display: block;
    padding: 0px !important;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden !important;
    outline: 0;
    width: 100%;
    height: unset !important;
    min-height: 100px;
    max-width: 100vw;
    max-height: 100vh;
    min-width: 100px;
    resize: both;
    /*background: white;*/
    box-shadow:
        0 5px 5px -3px #0003,
        0 8px 10px 1px #00000024,
        0 3px 14px 2px #0000001f;
}

@media only screen and (max-width: 1279px) {
    .mat-mdc-dialog-container {
        display: block;
        padding: 0px !important;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: unset !important;
        outline: 0;
        width: 100% !important;
        height: 100vh;
        min-height: inherit;
        max-height: 100vh;
    }
}

@media only screen and (max-width: 959px) {
    .mat-mdc-dialog-container {
        display: block;
        padding: 0px;
        border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;
        outline: 0;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        max-height: 100vh;
    }
}

.mat-mdc-slide-toggle {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    display: flex !important;
    /*width: 100%;*/
}

.mat-mdc-paginator-sticky {
    bottom: -10px;
    position: sticky;
    z-index: 999;
}

.hide-item {
    display: none !important;
}

th.select-menu {
    display: table-cell !important;
    padding-right: 8px;
}

.toolbar {
    width: 100%;
}

.toolbar-mobil {
    padding: 4px;
    width: 100%;
    color: inherit;
    box-shadow: none !important;
}

#toast-container > div {
    opacity: 0.95 !important;
}

.dialog-mobile {
    min-width: none !important;
}

.color-preview {
    margin-left: 8px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    padding: 5px;
    text-align: center;
}

.mat-badge-content {
    font-weight: 500 !important;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
    right: -8px !important;
    z-index: 100;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
    bottom: 0px !important;
}

.mat-badge-small .mat-badge-content {
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.weekend-style {
    background: #ffe082;
    border-radius: 100%;
}

:root {
    --border-color: #3c4252;
    --glow-color: #3072bd;
    --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.58);
}

.multiline-tooltip {
    white-space: pre-line;
}

.avatar-big {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    max-width: none;
}

.avatar-bigger {
    vertical-align: middle;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cover;
    max-width: none;
}

.avatar-mini {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    max-width: none;
}

.toast-container .ngx-toastr {
    min-width: 300px;
    width: unset !important;
}

.mat-mdc-table .mat-mdc-header-cell {
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.65);
}

table .mat-mdc-table {
    width: 100%;
    border-collapse: collapse;
}

.mat-mdc-table thead {
    z-index: 1000;
    //position: sticky;
}

.mat-mdc-header-row {
    height: 48px !important;
}

.mat-mdc-footer-row {
    height: 48px !important;
}

th.mat-mdc-header-cell {
    //border-bottom: 1px solid #eae5e5;
    //border-top: 1px solid #eae5e5;
    padding-right: 8px !important;
    padding-left: 8px !important;
    background: #e2e2e2 !important;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65) !important;
}

td.mat-mdc-cell {
    border-bottom: 1px solid #eae5e5;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: 1px solid #eae5e5 !important;
}

th.mat-mdc-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-mdc-footer-cell:last-of-type {
    border-right: 1px solid #eae5e5;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
    border-left: 1px solid #eae5e5;
}

tfoot > tr:last-child {
    td.mat-mdc-footer-cell:last-of-type {
        border-collapse: separate;
        border-bottom-right-radius: 4px;
    }

    td.mat-mdc-footer-cell:first-of-type {
        border-collapse: separate;
        border-bottom-left-radius: 4px;
    }
    td.mat-mdc-footer-cell {
        border-bottom: 1px solid rgb(234, 229, 229);
    }
}

thead > tr:first-child {
    th.mat-mdc-header-cell:last-of-type {
        border-collapse: separate;
        border-top-right-radius: 4px;
    }

    th.mat-mdc-header-cell:first-of-type {
        border-collapse: separate;
        border-top-left-radius: 4px;
    }
}

/* tinymce - full width editor*/
.tox {
    width: 0px !important;
    .tox-mbtn {
        width: auto !important;
    }
}
.tox-tinymce {
    width: auto !important;
}
.tox-statusbar__branding {
    display: none;
}
.tox-editor-dock-fadeout {
    opacity: 1 !important;
    visibility: visible !important;
}
.tox {
    .tox-notification--warning {
        display: none !important;
    }
    .tox-promotion {
        display: none !important;
    }
    .tox-collection--grid .tox-collection__group {
        display: flex;
        max-height: 208px;
        overflow-x: hidden;
        flex-wrap: nowrap !important;
        overflow-y: auto;
        padding: 0;
        flex-direction: column;
    }
    .tox-collection__item-label {
        white-space: nowrap;
    }
    .tox-autocompleter .tox-menu {
        max-width: unset !important;
    }
}
.tox-shadowhost.tox-fullscreen,
.tox.tox-tinymce.tox-fullscreen {
    max-height: calc(100vh - 120px) !important;
    right: 10px;
    padding-left: 8px;
}

/*attendance*/
:root {
    --present: #43a047;
    --tardy: #c77f4b;
    --excused: #41a8d4;
    --permitted: #7e57c1;
    --absent: #d32f2f;
    --not-taken: #607d8b;
}

.attendance_attended {
    background-color: var(--present);
    color: white !important;
    border-radius: 2px !important;
}

.truncate {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attendance_tardy {
    background-color: var(--tardy);
    color: white !important;
    border-radius: 2px !important;
}

.attendance_absent {
    background-color: var(--absent);
    color: white !important;
    border-radius: 2px !important;
}

.attendance_permitted {
    background-color: var(--permitted);
    border-radius: 2px !important;
}

.attendance_excused {
    background-color: var(--excused);
    border-radius: 2px !important;
    color: white;
}

.attendance_daily_absent {
    background-color: #990000;
    border-radius: 2px !important;
    color: white !important;
}

.attendance_not_yet {
    background-color: #dedede;
    border-radius: 2px !important;
    color: gray;
}

.attendance_not_taken {
    background-color: var(--not-taken);
    border-radius: 2px !important;
    color: white;
}

.attendance_attended_color {
    color: var(--present);
}

.attendance_tardy_color {
    color: var(--tardy);
}

.attendance_absent_color {
    color: var(--absent);
}

.attendance_permitted_color {
    color: var(--permitted);
}

.attendance_excused_color {
    color: var(--excused);
}

.attendance_daily_absent_color {
    color: #d32f2f;
}

.attendance_not_yet_color {
    color: #9a9a9a;
}

.additional-selection {
    opacity: 0.75;
    font-size: 0.75em;
}

/* SPLASH SCREEN */

body fuse-splash-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    color: #f9fafb;
    z-index: 999999;
    pointer-events: none;
    opacity: 1;
    visibility: visible;
    transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

body fuse-splash-screen img {
    width: 256px;
    max-width: 256px;
}

body fuse-splash-screen .spinner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    width: 56px;
}

body fuse-splash-screen .spinner-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

body fuse-splash-screen .spinner > div {
    width: 12px;
    height: 12px;
    background-color: #1e96f7;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
    animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

body fuse-splash-screen .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

body fuse-splash-screen .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes fuse-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

body:not(.fuse-splash-screen-hidden) {
    overflow: hidden;
}

body.fuse-splash-screen-hidden fuse-splash-screen {
    visibility: hidden;
    opacity: 0;
}

/* Full Calendar*/

.demo-app {
    display: flex;
    min-height: 100%;
    font-size: 14px;
    height: 100%;
}

.demo-app-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
    padding: 2em;
}

.demo-app-main {
    flex-grow: 1;
    padding: 8px;
    height: 100%;
}

.fc {
    /* the calendar root */
    margin: 0 auto;
    height: 100%;
    padding-bottom: 32px;
}

/* Remove that awful yellow color and border from today in Schedule */
.fc-state-highlight {
    opacity: 0;
    border: none;
}

.fc a {
    text-decoration: none !important;
}

.fc a:hover {
    text-decoration: none !important;
    background-color: lightgrey;
    border-radius: 2px;
    opacity: 1;
}

/* Styling for each event from Schedule */
.fc-time-grid-event.fc-v-event.fc-event {
    border-radius: 4px;
    border: none;
    padding: 5px;
    opacity: 0.65;
    left: 5% !important;
    right: 5% !important;
}

/* Bolds the name of the event and inherits the font size */
.fc-event {
    border: unset;
}

.fc-timegrid-event-harness {
    box-shadow:
        0 2px 4px -1px #0003,
        0 4px 5px #00000024,
        0 1px 10px #0000001f;
    border: unset;
    opacity: 0.9;
}

.fc-timegrid-event-harness :hover {
    opacity: 1;
}
/* Remove the header border from Schedule */
.fc td,
.fc th {
    border-width: 1px !important;
    padding: 0 !important;
    vertical-align: top !important;
}

/* Inherits background for each event from Schedule. */
.fc-event .fc-bg {
    z-index: 1 !important;
    background: inherit !important;
    opacity: 0.25 !important;
}

/* Normal font weight for the time in each event */
.fc-time-grid-event .fc-time {
    font-weight: normal !important;
}

/* Apply same opacity to all day events */
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
    opacity: 0.65 !important;
    margin-left: 12px !important;
    padding: 5px !important;
}

/* Apply same opacity to all day events */
.fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
    opacity: 0.65 !important;
    margin-left: 12px !important;
    padding: 5px !important;
}

/* Material design button */
.fc-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    min-height: 36px;
    min-width: 20px;
    line-height: 36px;
    vertical-align: middle;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    text-align: center;
    border-radius: 2px;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    border: 0;
    padding: 0 6px;
    margin: 6px 8px;
    letter-spacing: 0.01em;
    background: transparent;
    color: currentColor;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    font-style: inherit;
    font-variant: inherit;
    font-family: inherit;
    text-decoration: none;
    overflow: hidden;
    -webkit-transition:
        box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition:
        box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
        background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.fc-button:hover {
    background-color: rgba(158, 158, 158, 0.2);
    text-decoration: none;
}

.fc-button:focus {
    text-decoration: none;
}

/* The active button box is ugly so the active button will have the same appearance of the hover */
.fc-state-active {
    background-color: rgba(158, 158, 158, 0.2);
}

/* Not raised button */
.fc-state-default {
    box-shadow: None;
}

.fc-v-event {
    border: unset !important;
    border: unset !important;
}

.fc .fc-view-harness {
    flex-grow: 1;
    position: relative;
    width: 100%;
}

.fc .fc-list-table td {
    padding: 8px 14px !important;
}

.fc .fc-daygrid-event > .fc-event-title {
    color: black;
}

.fc .fc-daygrid-event > .fc-event-time {
    color: #3c4252;
}

.fc .fc-toolbar-title {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.ag-theme-material .ag-cell-inline-editing {
    height: 48px !important;
}

/*Glow-Pulse started course*/

.glow-pulse {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
}

.blob.mat-badge .mat-badge-content {
    border-radius: 50%;
    height: 18px;
    width: 18px;
    transform: scale(1);
    animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes cssload-animball_one {
    0% {
        position: absolute;
    }

    50% {
        top: 29px;
        left: 29px;
        position: absolute;
        opacity: 0.5;
    }

    100% {
        position: absolute;
    }
}

@-o-keyframes cssload-animball_one {
    0% {
        position: absolute;
    }

    50% {
        top: 29px;
        left: 29px;
        position: absolute;
        opacity: 0.5;
    }

    100% {
        position: absolute;
    }
}

@-ms-keyframes cssload-animball_one {
    0% {
        position: absolute;
    }

    50% {
        top: 29px;
        left: 29px;
        position: absolute;
        opacity: 0.5;
    }

    100% {
        position: absolute;
    }
}

@-webkit-keyframes cssload-animball_one {
    0% {
        position: absolute;
    }

    50% {
        top: 29px;
        left: 29px;
        position: absolute;
        opacity: 0.5;
    }

    100% {
        position: absolute;
    }
}

@-moz-keyframes cssload-animball_one {
    0% {
        position: absolute;
    }

    50% {
        top: 29px;
        left: 29px;
        position: absolute;
        opacity: 0.5;
    }

    100% {
        position: absolute;
    }
}

@keyframes cssload-animball_two {
    0% {
        transform: rotate(0deg) scale(1);
    }

    50% {
        transform: rotate(360deg) scale(1.3);
    }

    100% {
        transform: rotate(720deg) scale(1);
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #6cad96; /* rgba(20,105,69,.7);*/
    }
    28% {
        transform: translateY(-7px);
        background-color: #9ecab9; /*rgba(20,105,69,.4);*/
    }
    44% {
        transform: translateY(0px);
        background-color: #b5d9cb; /*rgba(20,105,69,.2);*/
    }
}

@-o-keyframes cssload-animball_two {
    0% {
        -o-transform: rotate(0deg) scale(1);
    }

    50% {
        -o-transform: rotate(360deg) scale(1.3);
    }

    100% {
        -o-transform: rotate(720deg) scale(1);
    }
}

@-ms-keyframes cssload-animball_two {
    0% {
        -ms-transform: rotate(0deg) scale(1);
    }

    50% {
        -ms-transform: rotate(360deg) scale(1.3);
    }

    100% {
        -ms-transform: rotate(720deg) scale(1);
    }
}

@-webkit-keyframes cssload-animball_two {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
    }

    50% {
        -webkit-transform: rotate(360deg) scale(1.3);
    }

    100% {
        -webkit-transform: rotate(720deg) scale(1);
    }
}

@-moz-keyframes cssload-animball_two {
    0% {
        -moz-transform: rotate(0deg) scale(1);
    }

    50% {
        -moz-transform: rotate(360deg) scale(1.3);
    }

    100% {
        -moz-transform: rotate(720deg) scale(1);
    }
}

.ag-row {
    border-right: 1px solid #dcdcdc !important;
}

.ag-row:hover {
    background: #d9d9d9 !important;
}

.ag-theme-material .ag-cell,
.ag-theme-material .ag-full-width-row .ag-cell-wrapper.ag-row-group {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.mat-expansion-panel {
    box-shadow: none !important;
    padding: 0px !important;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
    /*box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px !important;*/
    box-shadow:
        rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
        rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}

tr.highlight {
    background: #eff3f7 !important;
    /*color: white !important;*/
}

@media only screen and (max-width: 959px) {
    .apexcharts-legend {
        display: block !important;
    }
}

.mat-mdc-table {
    overflow: auto;
    width: 100%;
    table-layout: inherit;
    &.resizing {
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: col-resize;
    }
    .mat-mdc-cell {
        span {
            text-overflow: ellipsis;
        }
        //background: white;
    }
    .mat-mdc-header-cell {
        //border-bottom-style: none;
        //position: relative;
        &:not(:last-child) {
            .resize-holder {
                cursor: col-resize;
                width: 20px;
                height: 100%;
                //position: absolute;
                right: -10px;
                top: 0;
                z-index: 1;
            }
        }
    }
    .mat-mdc-cell,
    .mat-mdc-header-cell {
        border-right: 1px solid rgba(0, 0, 0, 0.08);
        &:not(:nth-child(1)) {
            padding: 0 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        }
    }
}

.mat-grid-tile-content {
    align-items: flex-start !important;
}

.mat-mdc-icon-button svg,
.mat-mdc-icon-button img {
    width: unset !important;
    height: 20px !important;
}

.custom-tooltip {
    width: 150px;
    height: 70px;
    border: 1px solid cornflowerblue;
    overflow: hidden;
}

.custom-tooltip p {
    margin: 5px;
    white-space: nowrap;
}

.custom-tooltip p:first-of-type {
    font-weight: 500;
}

.inline-editor {
    outline: 1px solid lightgray;
    border-radius: 2px;
    margin-bottom: 8px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    min-height: 64px;
}
.inline-editor:focus,
.inline-editor:hover {
    outline: 2px solid #1976d2;
}

.mat-mdc-tab-label {
    font-weight: 450;
}
.h-100-p {
    height: 100%;
}

.mat-mdc-button {
    font-size: 13px !important;
    white-space: nowrap;
    padding: 0 12px !important;
}

.mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tooltip-multiline {
    white-space: pre;
}

@-moz-document url-prefix() {
    .cdk-virtual-scrollable {
        overflow: auto !important;
    }
}
/*
.cdk-virtual-scrollable:hover {
    overflow: overlay !important;
}
*/
.as-split-gutter-icon {
    background: #f6f6f6;
    border-radius: 9999px;
}
.as-split-gutter-icon {
    background: white;
}

.as-split-area {
    margin-right: 4px;
    margin-left: 4px;
    padding-right: 4px;
    padding-left: 4px;
}

.as-split-gutter-icon:hover {
    background-color: #ededed;
}

fieldset {
    padding-top: 0px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.09);
    //box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

mark {
    outline: 1px solid red;
    font-weight: 500;
}

.ag-header {
    background-color: #f6f6f6 !important;
}

.ag-header-cell,
.ag-header-group-cell {
    padding-left: 4px;
    padding-right: 4px;
}
/* Style scrollbars on platforms other than MacOS and iOS */
@media only screen and (min-width: 960px) {
    body:not(.os-mac) {
        ::-webkit-scrollbar:hover {
            width: 4px;
            height: 4px;
            background-color: rgba(0, 0, 0, 0.06);
        }
        ::-webkit-scrollbar:active {
            width: 4px;
            height: 4px;
            background-color: rgba(0, 0, 0, 0.06);
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 20px;
            box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        }

        ::-webkit-scrollbar-thumb:active {
            border-radius: 20px;
            box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        }
    }
}

.mat-mdc-checkbox label {
    white-space: nowrap;
}

.mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 16px;
    padding: 0px;
}
.mat-mdc-icon-button.mat-mdc-button-base {
    width: var(--mdc-icon-button-state-layer-size) !important;
    height: var(--mdc-icon-button-state-layer-size) !important;
    padding: 8px !important;
}

.mat-mdc-form-field .mat-datepicker-toggle .mat-mdc-button-base {
    padding: 0px !important;
    height: 28px !important;
    width: 28px !important;
}
.mdc-dialog__title {
    padding: 0 16px 8px !important;
}

@keyframes button-spinner {
    to {
        transform: rotate(360deg);
    }
}

.button-spinner:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: button-spinner 0.8s linear infinite;
}

.hide-btn-text {
    color: transparent !important;
}

.mat-glow {
    animation: glow 1s infinite alternate;
}

@keyframes glow {
    from {
        box-shadow: 0 0 0 0 var(--glow-color);
    }
    to {
        box-shadow: 4px 4px 4px 4px var(--glow-color);
    }
}
.item-number {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 13px;
    background-color: var(--mat-badge-background-color);
    color: var(--mat-badge-text-color);
    text-align: center;
}

.mat-mdc-select-panel {
    max-height: 296px !important;
}
.mat-mdc-chip {
    font-size: 12px !important;
    font-weight: 400 !important;
}
.mat-icon {
    /*font-size: 18px !important;*/
    height: 20px !important;
    width: 20px !important;
}
.mat-mdc-option {
    font-size: 14px !important;
}

.mat-mdc-list-option {
    padding-left: 8px !important;
    //border: 1px solid lightgrey !important;
}

.mat-mdc-optgroup-label {
    font-size: 14px !important;
    font-weight: 800;
    .mdc-list-item__primary-text {
        width: 100%;
        .mat-mdc-option {
            min-height: 36px;
        }
    }
}

.mat-mdc-button-base {
    min-height: 40px;
    min-width: 40px;
}

.loading-shade {
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.mdc-list-item.mdc-list-item--with-one-line {
    height: 64px !important;
}
.mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 32px !important;
}

.dialog-custom-shadow .mat-mdc-dialog-container {
    box-shadow:
        rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
        rgba(90, 125, 188, 0.05) 0px 0.25em 1em !important;
}
.mat-mdc-menu-item-text {
    white-space: nowrap !important;
}
.mat-mdc-menu-panel {
    max-width: none !important;
}
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    display: inline-flex;
    align-items: center;
}
