html,
body {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: white;
}

body {
    &.boxed {
        max-width: 1200px;
        margin: 0 auto;

        //@include mat-elevation(8);
    }
}

.font-mono {
    font-family:
        IBM Plex Mono,
        ui-monospace,
        SFMono-Regular,
        Menlo,
        Monaco,
        Consolas,
        Liberation Mono,
        Courier New,
        monospace !important;
}
