/* This file meant to be imported only once! Use fuse.scss to access*/
/* to the core Fuse and Angular Material mixins*/

@use '@angular/material' as mat;
/* Fuse*/
@import 'fuse';

/* Perfect scrollbar*/
/*@import url(~perfect-scrollbar/css/perfect-scrollbar);*/

/* Include core Angular Material styles*/
@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-typographies();

/* Setup the typography*/
/*@include angular-material-typography($typography);*/

/* Partials*/
@import 'partials/reset';
/*@import 'partials/normalize';*/
@import 'partials/scrollbars';
@import 'partials/helpers';
@import 'partials/global';
@import 'partials/icons';
/*@import 'partials/colors';*/
/*@import 'partials/material';*/
@import 'partials/angular-material-fix';
@import 'partials/typography';
/* Plugins*/
@import 'partials/plugins/plugins';
:root {
    //Font size
    --mat-menu-item-label-text-size: 14px;
    --mdc-outlined-text-field-label-text-size: 13px;
    --mat-select-trigger-text-size: 13px;
    --mat-form-field-subscript-text-size: 11px;
    --mat-form-field-subscript-text-weight: 400;
    // Letter spacing
    --mdc-outlined-text-field-label-text-tracking: 0;
    --mat-menu-item-label-text-tracking: 0;
    --mdc-form-field-label-text-tracking: 0;
    --mat-table-row-item-label-text-tracking: 0;
    --mat-select-trigger-text-tracking: 0;
    --mat-option-label-text-tracking: 0;
    --mdc-list-list-item-label-text-tracking: 0;
    --mat-form-field-subscript-text-tracking: 0;
    --mat-form-field-container-text-tracking: 0;
    --mat-select-disabled-trigger-text-color: rgb(0, 0, 0, 0.58);
    --mdc-switch-disabled-selected-icon-opacity: 1;
    --mdc-switch-disabled-unselected-icon-opacity: 1;
    --mdc-switch-disabled-track-opacity: 0.25;
    --mdc-list-list-item-selected-container-color: #ededed;
    //--mat-select-trigger-text-line-height: 44px;
}

.density-4 {
    @include mat.elevation-classes();
    @include mat.app-background();
    @include mat.form-field-density(-5);
    //@include mat.checkbox-density(-3);
    @include mat.select-density(-5);
    @include mat.datepicker-density(-5);
    @include mat.option-density(-5);
    @include mat.radio-density(-5);
}

.search-form-field {
    .mat-mdc-form-field {
        padding-top: 6px;
        .mat-mdc-form-field-subscript-wrapper {
            height: 6px;
        }
    }
}

.hidden-subscipt-form-field {
    .mat-mdc-form-field {
        padding-top: 0px;
        .mat-mdc-form-field-subscript-wrapper {
            height: 0px;
        }
    }
}

.mdc-label {
    white-space: nowrap;
}
